/* CSS */
@import url("https://fonts.cdnfonts.com/css/blackberry-jam-personal-use?styles=71303");

.routesAction {
  display: flex;
  justify-content: space-between;
  color: #0b6699;
}

.routesOrderList {
  max-height: 85vh !important;
  overflow-y: auto;
}

.routeEditLink {
  text-decoration: none;
  color: #0b6699;
  font-size: 16px;
  font-weight: 700;
}

.routeOrderObject {
  /* width: 100%; */
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding-top: 20px;
  padding: 10px 10px 10px 35px !important;
  font-size: 14px;
  margin-bottom: 10px;
}

.routeOrderObject {
  border: 1px solid transparent; /* Initial border */
  padding: 10px;
}

.routeOrderObject:hover {
  background-color: rgb(203 213 225 / var(--tw-bg-opacity)); /* Hover background color */
}

.routeOrderObject:focus-within {
  border-color: blue; /* Border color on click */
}

.routeOrderObject input[type="radio"] {
  margin-right: 5px;
}

.routePharmacy {
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 15px 28px !important;
  margin-left: 10px;
  background-color: #0b6699;
  color: #f1f1f1;
  margin-top: 10px !important;
}

.routeOrderTotalCopay {
  background-color: #05b321;
  font-size: 14px !important;
  color: #f1f1f1;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
}

.route-note-div {
  background-color: #0b6699;
  border-radius: 15px;
  /* min-width: 50%;
  min-height: 55%; */
  padding: 12px;
  margin: 10px;
  height: 100px;
}

.route-cust-name {
  font-weight: 800;
  font-size: 16px;
  margin: 0;
}

.route-cust-detail {
  display: flex;
}

.route-detail-div {
  display: flex;
  margin-right: 35px;
  align-items: center;
}

.route-detail-in-text {
  font-weight: 500;
  font-size: 15px !important;
  margin: 0;
}

.route-addres-text {
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  width: 310px;
}

.addres-text{
  font-size: 13px;
}

.route-pharmacy-addres-text {
  font-weight: 500;
  font-size: 15px !important;
  margin: 0
}

.route-address-div {
  display: flex;
  margin-right: 180px;
}

.route-address-div2 {
  display: flex;
  margin-right: 40px;
}

.route-apt-text {
  font-weight: 800;
  margin: 0;
}

/* .route-note-div{
  background-color: #0B6699;
  border-radius: 15px;
  min-width: 300px;
  width: 300px;
  min-height: 130px;
  padding: 20px;
} */

.route-note-head {
  margin: 0;
  color: white;
  font-size: 14px !important;
  text-align: center;
}

.route-note-text {
  color: white;
  font-size: 10px !important;
  text-align: center;
}

.route-order-customer,
.route-order-address {
  display: flex;
  align-items: center;
}

.route-order-id {
  font-weight: 600;
  font-size: 15px !important;
  margin: 0;
  text-decoration: none;
}

.route-order-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  
}

.route-urgent-tag {
  padding: 0px 30px;
  border: 1px solid;
  font-weight: 700;
  font-size: 16px;
  border-radius: 50px;
  max-width: 130px;
}

.route-detail-in-icon {
  margin-right: 10px;
  width: 15px;
  height: 15px;
}

.RouteMapToggles {
  display: flex;
  /* justify-content: space-around; */
  padding-left: 10px;
  background-color: #E2E2E2;
}
.detail-in-text {
  font-weight: 500;
  font-size: 11px;
  margin: 0;
}
.detail-in-icon {
  margin-right: 10px;
  width: 15px;
  height: 15px;
}

.label {
  font-size: 15px;
  display: flex;
  gap: 10px
}

.map-container {
  height: 400px;
  width: 100%;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.marker {
  background-color: #ff0000;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  position: absolute; /* Ensure position is absolute */
  transform: translate(-50%, -50%); /* Center the marker */
}
