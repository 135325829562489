.marker {
    background-color: #ff0000;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    position: absolute; /* Ensure position is absolute */
    transform: translate(-50%, -50%); /* Center the marker */
  }
  
  .route-order-pharmacy {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 2;
  }
  
  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer; /* Optional: indicates interactivity */
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px; /* Adjust the width as needed */
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
  
    height: 80px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the text */
    left: 50%;
    transform: translateX(-50%);
    
    /* Fade-in effect */
    opacity: 0;
    transition: opacity 0.3s;
    
    /* Arrow */
    /* Create a small triangle below the tooltip */
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }